body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 25px;
  height: 25px;
  align-self: center;
  border: 5px solid white;
  border-top: 5px solid #0f0f88; 
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

html::-webkit-scrollbar {
  width:0.5vw;
}

html::-webkit-scrollbar-thumb{
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
}

html::-webkit-scrollbar-thumb:hover{
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
}

html::-webkit-scrollbar-track{
  background-color: rgba(8, 24, 58, 1);
}

html::-webkit-scrollbar-track:hover{
  background-color: rgba(8, 24, 58, 0.8);
}
